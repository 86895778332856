<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Pohon </strong><small>Tambah Data Baru</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputPohon">
            <CRow>
              <CCol sm="6">
                <div class="form-group">
                <label>Publish Pohon</label>
                <select v-model="publish" placeholder="Pilihan" class="form-control">
                  <option value="" disabled selected>Pilihan</option>
                  <option value="Ya">Ya</option>
                  <option value="Tidak">Tidak</option>
                </select>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <!-- <CCol sm="12">
                <CInput
                  v-model="name"
                  label="Nama Pohon"
                  placeholder="Input Nama Pohon"
                />
              </CCol> -->
              
              <!-- <CCol sm="6">
                <CInput
                  v-model="no_reg_pohon"
                  label="No Reg Pohon"
                  placeholder="Input No Reg Pohon"
                />
              </CCol> -->
              
              <CCol sm="6">
                <div class="form-group">
                <label>Nama Pohon</label>
                <input type="hidden" v-model="name"  />  
                <select v-model="kategoriPohon" placeholder="Pilih Jenis" @change="updateName" class="form-control">
                  <option value="" disabled selected>Pilih Nama Pohon</option>
                  <option v-for="item in jenis" :key="item.id_informasi_pohon" :value="item.id_informasi_pohon">{{ item.judul_informasi_pohon }}</option>
                </select>
                </div>
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                <label>Tipe Pohon</label>
                <select v-model="type" placeholder="Pilih Tipe" class="form-control">
                  <option value="" disabled selected>Pilih Tipe</option>
                  <option value="pohonhidup">Pohon Hidup</option>
                  <option value="pohonditebang">Pohon Ditebang</option>
                </select>
                </div>
              </CCol>
              <!-- <CCol sm="12">
                <CInput
                  v-model="lokasi"
                  label="Lokasi Pohon"
                  placeholder="Lokasi Pohon"
                  readonly="true"
                  @click="openMapModal"
                />  
                <input type="hidden" v-model="lng" /> 
                <input type="hidden" v-model="lat" /> 
              </CCol> -->
              <CCol sm="6">
                <CInput
                  v-model="lng"
                  label="Longitude"
                  placeholder="Input Longitude"
                />  
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="lat"
                  label="Lattitude"
                  placeholder="Input Lattitude"
                />  
              </CCol> 
              <CCol sm="4">
                <CInput
                  v-model="tinggi"
                  label="Tinggi Pohon"
                  placeholder="Tinggi Pohon"
                />  
              </CCol> 
              <CCol sm="4">
                <CInput
                  v-model="diameter"
                  label="Diameter Pohon"
                  placeholder="Diameter Pohon"
                />  
              </CCol> 
              <CCol sm="4">
                <CInput
                  v-model="lebar_tajuk"
                  label="Lebar Tajuk Pohon"
                  placeholder="Lebar Tajuk Pohon"
                />  
              </CCol> 
              <CCol sm="3">
                <CInput
                  v-model="carbon"
                  label="Carbon Pohon"
                  placeholder="Carbon Pohon"
                />  
              </CCol> 
              <CCol sm="3">
                <CInput
                  v-model="carbondioksida"
                  label="CO2 Pohon"
                  placeholder="CO2 Pohon"
                />  
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="biomassa"
                  label="Biomasa Pohon"
                  placeholder="Biomasa Pohon"
                />  
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="oksigen"
                  label="Oksigen Pohon"
                  placeholder="Oksigen Pohon"
                />  
              </CCol>

              <!-- <CCol sm="6">
                <div class="form-group">
                  <label>Foto Pohon </label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol> -->

              <CCol sm="12">
                <CTextarea
                  v-model="address"
                  label="Alamat Pohon"
                  placeholder="Alamat Pohon"
                />  
              </CCol>   
            </CRow>          
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/pohon">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
      
    >
      <b>Error</b>! Data Pohon gagal diinput.
    </CModal>

    <!-- Google Maps Modal -->
    <CModal
      title="Pilih Lokasi Pohon"
      :show.sync="mapModal"
      size="lg"
      @ok="confirmLocation"
    >
      <div id="map" style="height: 400px; width: 100%;"></div>
      <template #footer>
        <CButton @click="mapModal = false" color="danger">Batal</CButton>
        <CButton @click="confirmLocation" color="success">Simpan</CButton>
      </template>
    </CModal>
   
  </div>
</template>
<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
import { gmapApi } from "vue2-google-maps";
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  data () {
    return {
      mapModal: false,  // Modal state
      map: null,  // Google Map instance
      marker: null,  // Fixed marker instance
      center: { lat: 0.026330, lng: 109.342503 },  
      publish : "",
      name : "",
      no_reg_pohon : "",
      type : "",
      lng : "",
      lat : "",
      tinggi : "",
      diameter : "",
      lebar_tajuk : "",
      carbon : "",
      carbondioksida : "",
      biomassa : "",
      // foto : "",
      address : "",
      oksigen: "",
      pohon : [],
      jenis : [],
      selected : [], // Must be an array reference!
      show: true,
      modalError: false,
      kategoriPohon: "",
      lokasi: ''
    }
  },
  mounted(){
    axios.get(process.env.VUE_APP_BASE_URL+"informasipohons")
        .then((response) => {
            this.jenis = response.data
    })
  },
  methods: {
    updateName(event) {
      // Find the selected item based on the selected category
      const selectedItem = this.jenis.find(item => item.id_informasi_pohon == event.target.value);
      console.log(event.target.value, JSON.stringify(this.jenis), selectedItem, selectedItem.judul_informasi_pohon)
      
      // Update the name variable if the item is found
      if (selectedItem) {
        this.name = selectedItem.judul_informasi_pohon; // Store the name of the selected tree
      } else {
        this.name = ''; // Reset if no item is found
      }
    },
    // Open modal and initialize the map
    openMapModal() {
      this.mapModal = true;
      this.$nextTick(() => {
        this.initializeMap();
      });
    },
    // Initialize Google Map
    initializeMap() {
      if (this.map) return;  // Prevent re-initialization

      const mapOptions = {
        center: this.center,
        zoom: 13,
      };

      this.map = new google.maps.Map(document.getElementById("map"), mapOptions);

      // Fixed marker in the center
      this.marker = new google.maps.Marker({
        position: this.map.getCenter(),
        map: this.map,
        draggable: false,
      });

      // Update coordinates when the map is moved
      this.map.addListener("center_changed", () => {
        const center = this.map.getCenter();
        this.lat = center.lat().toFixed(6);
        this.lng = center.lng().toFixed(6);
        this.marker.setPosition(center);
      });
    },
    // Confirm location and close modal
    confirmLocation() {
      this.mapModal = false;
      this.lokasi = this.lat+", "+this.lng
    },
    inputPohon: function(){
      // Simple POST request with a JSON body using axios
      const pohon = { 
                          publish:this.publish,
                          name:this.name,
                          no_reg_pohon: this.no_reg_pohon, 
                          type: this.type, 
                          lng: this.lng, 
                          lat: this.lat, 
                          tinggi: this.tinggi, 
                          diameter: this.diameter, 
                          lebar_tajuk: this.lebar_tajuk, 
                          carbon: this.carbon, 
                          carbondioksida: this.carbondioksida, 
                          biomassa: this.biomassa, 
                          // foto: this.foto,
                          address: this.address,
                          kategori_pohon: this.kategoriPohon,
                          oksigen: this.oksigen,
                          };
      // alert(JSON.stringify(anggota));
      axios.post(process.env.VUE_APP_BASE_URL+"pohon", pohon)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status===201){
              this.$router.push('/pohon');
            }
            else{
              this.modalError = true;
            }            
        })
    }
  }
}
</script>